import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(private http: HttpClient) {
  }

  public getHeader(headers: any) {
    if (headers) {
      headers = new HttpHeaders({'Content-Type': 'application/json', ...headers});
    }
    return {headers};
  }

  public httpGet(url: any) {
    return this.http.get(environment.baseUrl + url)
  }

  public httpGetMyAccount(url: any, headers?: any) {
    return this.http.get(environment.myAccountBaseUrl + url, this.getHeader(headers))
  }

  public httpPostMyAccount(url: string, body: any, headers?: any) {
    return this.http.post(environment.myAccountBaseUrl + url, body, this.getHeader(headers))
  }

  public httpPost(url: string, body: any, headers?: any) {
    return this.http.post(environment.baseUrl + url, body, this.getHeader(headers));
  }

  public httpDeleteMyAccount(url: string, headers?: any) {
    return this.http.delete(environment.myAccountBaseUrl + url, this.getHeader(headers));
  }

  public exportExcel(url: string) {
    window.location.href = url
  }
}
