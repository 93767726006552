export const environment = {
  baseUrl: 'https://staging.sso.auth.vettimes.co.uk/api/',
  myAccountBaseUrl: 'https://staging.sso.myaccount.vettimes.co.uk/api/',
  rcvsUrl: 'https://findavet.rcvs.org.uk/find-a-vet-surgeon/',
  myJobsUrl: 'https://staging.myjobs.vettimes.co.uk/#/',
  vettimesUrl: 'https://staging.vettimes.co.uk/',
  subDomain: '.vettimes.co.uk',
  googleAnalyticsKey: 'SSO-V3',
  production: false
};
