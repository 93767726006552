import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../shared/services/api.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {LocationService} from '../../shared/services/location.service';
import {CookieService} from "ngx-cookie-service";
import { environment } from '../../../environments/environment';
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
})
export class RegisterComponent {
  form!: FormGroup;
  curr_step: number | undefined;
  token: string | null = null;
  showPassword: boolean = false;
  showRcvs: boolean = true;
  errorMessage: any = {};
  loader: boolean = false;
  showResendLink: boolean = false;
  countries: any = [];
  rcvsFieldsName: string = '';
  isDisabled: boolean = true;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private locationService: LocationService,
    private cookieService: CookieService,
    private googleAnalytics: GoogleAnalyticsService
  ) {
  }

  ngOnInit(): void {
    this.validation();
    this.route.queryParams.subscribe((params) => {
      if (params['token']) {
        this.emailVerification(params['token']);
      } else {
        this.curr_step = 1;
      }
    });

    this.getCountries();
  }

  setCurrentLocation() {

    this.locationService.getIpAddress().subscribe(data => {

      const ipAddress = data.ip

      this.locationService.getLocation(ipAddress).subscribe(res => {

        const country_name = this.getCountryName(res.data)

        if (country_name) {
          const country = this.countries.find((c: any) => c.country_name.toLowerCase() == country_name.toLowerCase())

          if (country) {
            this.form.patchValue({country_of_residence: country?.country_name});
            this.onCountryChange();
          }

        }
      }, error => {
        console.error('Error fetching location', error);
      })
    }, error => {
      console.error('Error fetching IP address', error);
    })
  }

  trackButtonClick(event: any) {

    const nameControl = this.form.get('name');
    const lastNameControl = this.form.get('last_name');
    const emailControl = this.form.get('email');
    const passwordControl = this.form.get('password');

    if (event.button === 'Create account') {
      event.payload = this.getBody()
      delete event.payload.password;

      this.form.markAllAsTouched();
      if (this.form.invalid) {
        return;
      }

    } else if (event.button === 'Next') {
      if (
        nameControl?.invalid ||
        lastNameControl?.invalid ||
        emailControl?.invalid ||
        passwordControl?.invalid
      ) {
        return;
      }
    }


    this.googleAnalytics.trackButtonClick(event);
  }

  getCountryName(data: any) {
    switch (data.country_name) {
      case 'United Kingdom of Great Britain and Northern Ireland':
        return data.region_name
      case 'United States of America':
        return 'United States'
      case 'Moldova (the Republic of)':
        return 'Moldova, Republic Of'
      case 'Bolivia (Plurinational State of)':
        return 'Bolivia'
      case 'Venezuela (Bolivarian Republic of)':
        return 'Venezuela'
      case 'North Macedonia':
        return 'Macedonia, Former Yugoslav Republic Of'
      default:
        return data.country_name
    }
  }

  getCountries() {
    this.apiService.httpGetMyAccount('user/countries').subscribe(
      (res: any) => {
        this.countries = res.data;
        this.setCurrentLocation();
      },
      (err: any) => {
        console.error('Error fetching countries', err);
      }
    );
  }

  validation() {
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(?!.*--)(?!.*'')(?!.*-.*-)(?!.*'.*')[a-zA-Z-' ]+$/
          ),
        ],
      ],
      last_name: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(?!.*--)(?!.*'')(?!.*-.*-)(?!.*'.*')[a-zA-Z-' ]+$/
          ),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          ),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          this.customPasswordValidator(),
        ],
      ],
      job_role: ['C', Validators.required],
      country_of_residence: ['', Validators.required],
      rcvs: [
        '',
        [
          Validators.minLength(7),
          Validators.maxLength(7),
          Validators.pattern(/^[a-zA-Z0-9]+$/),
        ],
      ],
      agreeTerms: [false]
    });
  }

  togglePasswordVisibility(e: Event): void {
    e.preventDefault();
    this.showPassword = !this.showPassword;
  }

  customPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const password = control.value;

      // Check if password has at least 8 characters without counting starting or ending spaces
      if (password.trim().length < 8) {
        return {minlength: true};
      }

      return null; // Return null if validation passes
    };
  }

  next() {
    const nameControl = this.form.get('name');
    const lastNameControl = this.form.get('last_name');
    const emailControl = this.form.get('email');
    const passwordControl = this.form.get('password');

    nameControl?.markAsTouched();
    lastNameControl?.markAsTouched();
    emailControl?.markAsTouched();
    passwordControl?.markAsTouched();

    if (
      nameControl?.invalid ||
      lastNameControl?.invalid ||
      emailControl?.invalid ||
      passwordControl?.invalid
    ) {
      return;
    }

    this.checkEmail();
  }

  checkEmail() {
    this.spinner.show();
    this.loader = true;
    const {email} = this.form.value;
    this.apiService.httpPost('auth/check-email', {email}).subscribe(
      (res: any) => {
        this.curr_step = 2;
        this.spinner.hide();
        this.loader = false;
        this.errorMessage.email = '';
      },
      (err) => {
        this.errorMessage.email = err.error.errors.email;
        this.spinner.hide();
        this.loader = false;
        this.showResendLink =
          err.error.errors.email === 'Email is already registered'
            ? false
            : true;
      }
    );
  }

  selectJobRole(e: MouseEvent, role: any): void {
    e.preventDefault();
    this.form.patchValue({job_role: role});

    this.rcvsValidation();
    this.isDisable();
  }

  onCountryChange() {
    this.rcvsValidation()
  }

  rcvsValidation() {
    const rcvsControl: any = this.form.get('rcvs');
    const role: any = this.form.value['job_role'];
    const country = this.countries.find((c: any) => c.country_name === this.form.get('country_of_residence')?.value);
    const selectedValue = country.country_code;

    // surgeon
    if ((selectedValue && selectedValue.startsWith('GB')) && (role === 'C' || role === 'D')) {
      this.rcvsFieldsName = 'RCVS number';
      rcvsControl.setValidators([
        Validators.minLength(7),
        Validators.maxLength(7),
        Validators.pattern(/^[a-zA-Z0-9]+$/),
      ]);
      this.showRcvs = true;
    }

    if (!(selectedValue && selectedValue.startsWith('GB')) && (role === 'C' || role === 'D')) {
      this.rcvsFieldsName = 'Membership number';
      rcvsControl.setValidators([
        Validators.pattern(/^[a-zA-Z0-9]+$/),
      ]);
      this.showRcvs = true;
    }

    // Student veterinary nurse
    if (selectedValue && selectedValue.startsWith('GB') && role === 'G') {
      this.rcvsFieldsName = 'RCVS number';
      rcvsControl.setValidators([
        Validators.minLength(7),
        Validators.maxLength(7),
        Validators.pattern(/^[a-zA-Z0-9]+$/),
      ]);
      this.showRcvs = true;
    }

    if (!(selectedValue && selectedValue.startsWith('GB')) && role === 'G') {
      this.rcvsFieldsName = 'Membership number';
      rcvsControl.setValidators([
        Validators.pattern(/^[a-zA-Z0-9]+$/),
      ]);
      this.showRcvs = true;
    }

    // etc
    if (role === 'S' || role === 'O') {
      rcvsControl.clearValidators();
      this.showRcvs = false;
    }

    rcvsControl.updateValueAndValidity();
  }

  findRcvsNumber(e: any): void {
    e.preventDefault();
    window.open(environment.rcvsUrl, '_blank');
  }

  validateRcvsInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    let validValue = value.charAt(0).replace(/[^a-zA-Z0-9]/, '');

    if (this.rcvsFieldsName == 'RCVS number' && value.length < 8) {

      validValue += value.slice(1, 6).replace(/[^0-9]/g, '');

      if (value.length > 6) {
        validValue += value.charAt(6).replace(/[^a-zA-Z0-9]/, '');
      }

      validValue = validValue.slice(0, 7);

    } else if (this.rcvsFieldsName == 'Membership number') {
      validValue += value.slice(1).replace(/[^0-9]/g, '');
    }

    if (value !== validValue) {
      this.form.get('rcvs')?.setValue(validValue, { emitEvent: false });
    }

    this.errorMessage.rcvs = '';
    this.isDisable();
  }

  isDisable() {
    this.isDisabled = this.form.invalid || !this.form.value['agreeTerms'] ? true : false;
  }

  getBody() {
    this.form.value['password'] = this.form.value['password'].trim();
    if (this.rcvsFieldsName === 'Membership number')
      this.form.value['membership'] = this.form.value['rcvs'];
    if (
      !this.showRcvs ||
      this.rcvsFieldsName === 'Membership number' ||
      this.form.value['rcvs'] === ''
    )
      delete this.form.value['rcvs'];

    return this.form.value;
  }

  register() {
    this.spinner.show();
    this.loader = true;
    this.apiService.httpPost('auth/register', this.getBody()).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.loader = false;
        this.curr_step = 3;
      },
      (err: any) => {
        this.errorMessage.rcvs = err.error.errors.rcvs;
        this.spinner.hide();
        this.loader = false;
      }
    );
  }

  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.register();
  }

  resendVerificationEmail(e: any) {
    this.spinner.show();
    this.loader = true;
    e.preventDefault();
    const {email} = this.form.value;
    this.apiService
      .httpPost('auth/resend-email', {email})
      .subscribe(
        (res: any) => {
        this.toastr.success(res.message);
        this.spinner.hide();
        this.loader = false;
      }, (error: any) => {
        this.toastr.error(error.error.errors);
        this.spinner.hide();
        this.loader = false;
      }
    );
  }

  emailVerification(token: any) {
    this.apiService.httpGet(`auth/verify-email?token=${token}`).subscribe(
      (res: any) => {

        let user = res.data.user;

        localStorage.setItem('user', JSON.stringify(user))
        this.cookieService.set('sso_signed', res.data.token, 0, '/', environment.subDomain);
        this.spinner.hide();
        this.loader = false;
        this.curr_step = 5;
      },
      (err) => {
        this.curr_step = 4;
        this.spinner.hide();
        this.loader = false;
      }
    );
  }

  productLink() {
    window.open(environment.myJobsUrl);
  }
}
