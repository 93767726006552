import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {Router} from '@angular/router';
import {BehaviorSubject, map} from "rxjs";
import {CookieService} from "ngx-cookie-service";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class AuthService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser;

  constructor(private apiService: ApiService, private router: Router, private cookieService: CookieService) {
    const storedUser = localStorage.getItem('user');
    this.currentUserSubject = new BehaviorSubject<any>(storedUser ? JSON.parse(storedUser) : null);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public get getToken(): any {
    return this.cookieService.get('sso_signed');
  }

  login(data: any) {
    return this.apiService.httpPost('auth/login', data).pipe(map(response => {
      const {user, token} = (response as any).data;
      localStorage.setItem('user', JSON.stringify(user))

      this.cookieService.set('sso_signed', token, 0, '/', environment.subDomain);
      this.currentUserSubject.next(user);
      return user;
    }));
  }

  forgotPassword(data: any) {
    return this.apiService.httpPost('auth/forgot-password', data);
  }

  resetPassword(data: any, token: any) {
    return this.apiService.httpPost(`auth/reset-password?token=${token}`, data);
  }

  restoreAccount(token: any) {
    return this.apiService.httpGet(`auth/recover-email?token=${token}`);
  }

   logout() {
    if (this.cookieService.get('sso_signed')) {
      this.cookieService.delete('sso_signed', '/', environment.subDomain);
      localStorage.removeItem('user');
      this.router.navigate(['/login']);
    }
  }
}
